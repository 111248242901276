import { default as _404QwQ0KLoaZ3Meta } from "/codebuild/output/src3566314979/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_46_46_46slug_93eiC9qmzMVmMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _91_91filter_93_93D9zq9eCEpBMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexcByQLEk9mAMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notificationswNp26pxK19Meta } from "/codebuild/output/src3566314979/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as paymentsuqvxCvGw9rMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infovOgfQOWOzcMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securityM8DuhL1hv9Meta } from "/codebuild/output/src3566314979/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirmm3v9iT4ZpWMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexNxthkTBs1NMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93iO2zpAsPhDMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_936yC50V9EgZMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as loginTFPnw0CgwoMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45passwordZ7eL0efrGrMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordm3b0apB9uTMeta } from "/codebuild/output/src3566314979/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: _404QwQ0KLoaZ3Meta?.name ?? "404",
    path: _404QwQ0KLoaZ3Meta?.path ?? "/404",
    meta: _404QwQ0KLoaZ3Meta || {},
    alias: _404QwQ0KLoaZ3Meta?.alias || [],
    redirect: _404QwQ0KLoaZ3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93eiC9qmzMVmMeta?.name ?? "slug",
    path: _91_46_46_46slug_93eiC9qmzMVmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93eiC9qmzMVmMeta || {},
    alias: _91_46_46_46slug_93eiC9qmzMVmMeta?.alias || [],
    redirect: _91_46_46_46slug_93eiC9qmzMVmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_93D9zq9eCEpBMeta?.name ?? "account-bookings-filter",
    path: _91_91filter_93_93D9zq9eCEpBMeta?.path ?? "/account/bookings/:filter?",
    meta: _91_91filter_93_93D9zq9eCEpBMeta || {},
    alias: _91_91filter_93_93D9zq9eCEpBMeta?.alias || [],
    redirect: _91_91filter_93_93D9zq9eCEpBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/account/bookings/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: indexcByQLEk9mAMeta?.name ?? "account",
    path: indexcByQLEk9mAMeta?.path ?? "/account",
    meta: indexcByQLEk9mAMeta || {},
    alias: indexcByQLEk9mAMeta?.alias || [],
    redirect: indexcByQLEk9mAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationswNp26pxK19Meta?.name ?? "account-notifications",
    path: notificationswNp26pxK19Meta?.path ?? "/account/notifications",
    meta: notificationswNp26pxK19Meta || {},
    alias: notificationswNp26pxK19Meta?.alias || [],
    redirect: notificationswNp26pxK19Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: paymentsuqvxCvGw9rMeta?.name ?? "account-payments",
    path: paymentsuqvxCvGw9rMeta?.path ?? "/account/payments",
    meta: paymentsuqvxCvGw9rMeta || {},
    alias: paymentsuqvxCvGw9rMeta?.alias || [],
    redirect: paymentsuqvxCvGw9rMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/account/payments.vue").then(m => m.default || m)
  },
  {
    name: personal_45infovOgfQOWOzcMeta?.name ?? "account-personal-info",
    path: personal_45infovOgfQOWOzcMeta?.path ?? "/account/personal-info",
    meta: personal_45infovOgfQOWOzcMeta || {},
    alias: personal_45infovOgfQOWOzcMeta?.alias || [],
    redirect: personal_45infovOgfQOWOzcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/account/personal-info.vue").then(m => m.default || m)
  },
  {
    name: securityM8DuhL1hv9Meta?.name ?? "account-security",
    path: securityM8DuhL1hv9Meta?.path ?? "/account/security",
    meta: securityM8DuhL1hv9Meta || {},
    alias: securityM8DuhL1hv9Meta?.alias || [],
    redirect: securityM8DuhL1hv9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: confirmm3v9iT4ZpWMeta?.name ?? "experiences-parentId-serviceId-confirm",
    path: confirmm3v9iT4ZpWMeta?.path ?? "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmm3v9iT4ZpWMeta || {},
    alias: confirmm3v9iT4ZpWMeta?.alias || [],
    redirect: confirmm3v9iT4ZpWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexNxthkTBs1NMeta?.name ?? "experiences-parentId-serviceId",
    path: indexNxthkTBs1NMeta?.path ?? "/experiences/:parentId()/:serviceId()",
    meta: indexNxthkTBs1NMeta || {},
    alias: indexNxthkTBs1NMeta?.alias || [],
    redirect: indexNxthkTBs1NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93iO2zpAsPhDMeta?.name ?? "experiences-parentId-serviceId-return-bookingId",
    path: _91bookingId_93iO2zpAsPhDMeta?.path ?? "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93iO2zpAsPhDMeta || {},
    alias: _91bookingId_93iO2zpAsPhDMeta?.alias || [],
    redirect: _91bookingId_93iO2zpAsPhDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91accessKey_936yC50V9EgZMeta?.name ?? "gratuity-accessKey",
    path: _91accessKey_936yC50V9EgZMeta?.path ?? "/gratuity/:accessKey()",
    meta: _91accessKey_936yC50V9EgZMeta || {},
    alias: _91accessKey_936yC50V9EgZMeta?.alias || [],
    redirect: _91accessKey_936yC50V9EgZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/gratuity/[accessKey].vue").then(m => m.default || m)
  },
  {
    name: loginTFPnw0CgwoMeta?.name ?? "login",
    path: loginTFPnw0CgwoMeta?.path ?? "/login",
    meta: loginTFPnw0CgwoMeta || {},
    alias: loginTFPnw0CgwoMeta?.alias || [],
    redirect: loginTFPnw0CgwoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordZ7eL0efrGrMeta?.name ?? "reset-password",
    path: reset_45passwordZ7eL0efrGrMeta?.path ?? "/reset-password",
    meta: reset_45passwordZ7eL0efrGrMeta || {},
    alias: reset_45passwordZ7eL0efrGrMeta?.alias || [],
    redirect: reset_45passwordZ7eL0efrGrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45passwordm3b0apB9uTMeta?.name ?? "set-password",
    path: set_45passwordm3b0apB9uTMeta?.path ?? "/set-password",
    meta: set_45passwordm3b0apB9uTMeta || {},
    alias: set_45passwordm3b0apB9uTMeta?.alias || [],
    redirect: set_45passwordm3b0apB9uTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src3566314979/src/gx-booking/pages/set-password.vue").then(m => m.default || m)
  }
]